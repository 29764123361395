exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-public-index-js": () => import("./../../../src/pages/api-public/index.js" /* webpackChunkName: "component---src-pages-api-public-index-js" */),
  "component---src-pages-caracteristici-colaborare-index-js": () => import("./../../../src/pages/caracteristici/colaborare/index.js" /* webpackChunkName: "component---src-pages-caracteristici-colaborare-index-js" */),
  "component---src-pages-caracteristici-financiar-index-js": () => import("./../../../src/pages/caracteristici/financiar/index.js" /* webpackChunkName: "component---src-pages-caracteristici-financiar-index-js" */),
  "component---src-pages-caracteristici-gestionarea-task-urilor-index-js": () => import("./../../../src/pages/caracteristici/gestionarea-task-urilor/index.js" /* webpackChunkName: "component---src-pages-caracteristici-gestionarea-task-urilor-index-js" */),
  "component---src-pages-caracteristici-imagine-de-ansamblu-index-js": () => import("./../../../src/pages/caracteristici/imagine-de-ansamblu/index.js" /* webpackChunkName: "component---src-pages-caracteristici-imagine-de-ansamblu-index-js" */),
  "component---src-pages-caracteristici-index-js": () => import("./../../../src/pages/caracteristici/index.js" /* webpackChunkName: "component---src-pages-caracteristici-index-js" */),
  "component---src-pages-caracteristici-planificare-si-previziune-index-js": () => import("./../../../src/pages/caracteristici/planificare-si-previziune/index.js" /* webpackChunkName: "component---src-pages-caracteristici-planificare-si-previziune-index-js" */),
  "component---src-pages-contactati-ne-index-js": () => import("./../../../src/pages/contactati-ne/index.js" /* webpackChunkName: "component---src-pages-contactati-ne-index-js" */),
  "component---src-pages-echipa-noastra-index-js": () => import("./../../../src/pages/echipa-noastra/index.js" /* webpackChunkName: "component---src-pages-echipa-noastra-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-missing-translation-index-js": () => import("./../../../src/pages/missing-translation/index.js" /* webpackChunkName: "component---src-pages-missing-translation-index-js" */),
  "component---src-pages-politica-de-confidentialitate-index-js": () => import("./../../../src/pages/politica-de-confidentialitate/index.js" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-index-js" */),
  "component---src-pages-preturi-index-js": () => import("./../../../src/pages/preturi/index.js" /* webpackChunkName: "component---src-pages-preturi-index-js" */),
  "component---src-pages-termeni-si-conditii-index-js": () => import("./../../../src/pages/termeni-si-conditii/index.js" /* webpackChunkName: "component---src-pages-termeni-si-conditii-index-js" */),
  "component---src-templates-blog-blog-index-js": () => import("./../../../src/templates/blog/blog-index.js" /* webpackChunkName: "component---src-templates-blog-blog-index-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-features-feature-details-js": () => import("./../../../src/templates/features/feature-details.js" /* webpackChunkName: "component---src-templates-features-feature-details-js" */)
}

